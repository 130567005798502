<template>
  <div class="mt-0"> 
    <template v-if="webhook && webhook.identifier">
       
    <!-- REFRESH TOKEN MODAL: -->
    <b-modal 
      ref="confirm-refresh-modal" 
      centered 
      size="md"
      @hide="((e)=>{ if (loading.token) e.preventDefault()})"
      @ok="refreshToken()"
    >

      <template #modal-title>
        <span>Confirm refresh of this Token?</span>
      </template>
      
      <template #modal-ok>
        <div>
          <span v-if="!loading.token">Confirm</span>
          <b-spinner v-else small/>
        </div>
      </template>

      Refresh Token of the Webhook <b>{{webhook.identifier}}</b>?
      <span class="small text-secondary d-block">
        All systems that use this Token will cease triggering this Webhook until they're updated accordingly  
      </span>
    </b-modal>

    <!-- DELETE WEBHOOK MODAL: -->
    <b-modal 
      ref="confirm-delete-modal" 
      centered 
      size="md" 
      ok-variant="danger"
      @hide="((e)=>{ if (loading.deleteWebhook) e.preventDefault()})"
      @ok="deleteWebhook()"
    >

      <template #modal-title>
        <span> Delete this Webhook?</span>
      </template>
      
      <template #modal-ok>
        <div>
          <span v-if="!loading.deleteWebhook">Confirm</span>
          <b-spinner v-else small/>
        </div>
      </template>

      Confirm deletion of the Webhook <b>{{webhook.identifier}}</b>?<br>
      <span class="text-secondary small">It is vinculated to the Service <b>{{webhook.service.identifier}}</b> and Event <b>{{webhook.event.event_identifier}}</b></span>
    </b-modal>


    <b-card>
      <!-- HEADER:  -->
      <b-row>
        <b-col md="12">
          <div class="d-flex justify-content-between">

          <div>
            <h1 class="text-success mb-75">{{webhook.identifier}}</h1>
          </div>
          
          <span class="text-muted">
            <div class="text-right">
              <p class="mt-0 mb-0"><b class="">Created at:</b> {{formatLLL(webhook.created_at)}}</p>
              <p class="mt-0 p-0 small"> <span class=""><b>Last updated:</b></span> {{formatLLL(webhook.updated_at)}}</p>
            </div>
          </span>
          </div>


          <div class="d-flex justify-content-start">
            <h5 class="mr-2">Service: <span class="text-success">{{webhook.service.identifier}}</span></h5>
            <h5>Event: <span class="text-success">{{webhook.event.event_identifier}}</span></h5>
          </div>
          
       </b-col>

       <b-col md="12" >
        <div class="mt-2">
          <!-- EDIT IDENTIFIER ================ -->
          <b-row no-gutters class="mb-50">
            <b-col md="12" class="d-flex align-items-center">
              <b>Name:</b>
            </b-col>
            <b-col md="12" class="d-flex align-items-center mt-25 ">
              <!-- <b-overlay :show="loading.identifier" variant="none" spinner-small class="w-100" > -->
                <b-input-group class="input-group-merge" @click="shakeEditName()">
                  <b-input-group-prepend is-text >
                    <b-icon icon="link45deg" variant="secondary" scale="1.2"/>
                  </b-input-group-prepend>

                  <b-form-input
                    size="lg" 
                    name="webhook-identifier" 
                    :readonly="!!editInputBlocked.identifier" 
                    autocomplete="off" 
                    :disabled="editInputBlocked.identifier" 
                    v-model="mockIdentifier" 
                    class="text-secondary" 
                    ref="edit-identifier" 
                  />

                  <b-input-group-append is-text>
                    <b-button :disabled="userCantEdit" :class="shakingEditName ? 'p-0 px-75 py-25 animate__animated animate__headShake animate_faster':'p-0 px-75 py-25'" variant="outline-dark" @click="allowEditField('identifier')" v-if="editInputBlocked.identifier">
                      <b-icon icon="pencil-square" class="m-0 p-0" scale="1.1"/>
                    </b-button>
                    <b-button v-else class="py-50 px-1 rounded" variant="success" @click="updateName()">
                      <span class="small font-weight-bolder">SAVE</span>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              <!-- </b-overlay> -->
            </b-col>
          </b-row>
          <!-- ============================== -->


          <!-- EDIT TOKEN =================== -->
          
          <b-row no-gutters class="mt-0">
            <b-col md="12" class="d-flex align-items-center mt-1">
              <b>Token:</b>
            </b-col>
            <b-col md="12" class="d-flex align-items-center mt-25">

              <b-input-group class="input-group-merge" >
                
                <b-input-group-prepend is-text >
                  <b-icon icon="key-fill" variant="secondary" scale="1.2" rotate="-30"/>
                </b-input-group-prepend>

                <b-form-input 
                  name="webhook-token"
                  :readonly="!!editInputBlocked.token"  
                  :disabled="editInputBlocked.token" 
                  v-model="webhook.token" 
                  class="text-secondary" 
                  ref="edit-token"
                  :type="tokenVisible ? 'text': 'password'"
                  autocomplete="off"
                  size="lg"
                />

                <b-input-group-append is-text>
                  <b-tooltip target="edit-token-btn" triggers="hover" placement="bottom" variant="dark">
                    Refresh Webhook Token
                  </b-tooltip>

                  <b-button  class="p-0 px-75 py-25 mr-75" variant="outline-dark" @click="tokenVisible = !tokenVisible">
                    <b-icon :icon="tokenVisible ? 'eye' : 'eye-slash'" class="m-0 p-0" scale="1.2 " variant=""/>
                  </b-button>

                  <b-button :disabled="userCantEdit" class="p-0 px-75 py-25" variant="outline-dark" @click="$refs['confirm-refresh-modal'].show()" id="edit-token-btn">
                    <b-icon icon="arrow-repeat" class="m-0 p-0" scale="1.2" variant="" rotate="45"/>
                  </b-button>
                </b-input-group-append>

              </b-input-group>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="w-25">
              <b-button variant="outline-dark" class="px-50 py-50 small square rounded-bottom ml-75" @click="copyToken('raw')">
                <small class="text-secondary">Copy Token
                  <b-icon icon="clipboard" scale=".9"/>
                </small>
              </b-button>
              

              <!-- /webhook/:identificador_transmissão/:identificador_webhook/:token_webhook -->

              <!-- <b-button variant="outline-dark" class="px-50 py-50 small square rounded-bottom ml-75" @click="copyToken('query')">
                <small class="text-secondary">Copy Token as Query
                  <b-icon icon="clipboard" scale=".9"/>
                </small>
              </b-button> -->
            </b-col>
          </b-row>
          <!-- ============================= -->
          

          <b-row no-gutters class="mt-2" >

            <b-col md="12" class="d-flex align-items-center justify-content-between">
              <div class="ml-50">
                <b>Activation URL:</b>
              </div>

              <div class="d-flex justify-content-end align-items-center">
                <div class="d-flex mx-2 align-items-center">
                  <span class="mr-75 font-weight-bold small">Token as Query:</span>
                  <b-form-checkbox v-model="urlAsQuery"/>
                </div>
                <div class="d-flex mx-2 align-items-center">
                  <span class="mr-75 font-weight-bold small whitespace-nowrap">Run Options:</span>
                  <b-form-select :options="urlOptions" v-model="selectedUrlOption"/>
                </div>
              </div>

            </b-col>
            <b-col md="12" class="d-flex align-items-center mt-25 ">
              <!-- <b-overlay :show="loading.identifier" variant="none" spinner-small class="w-100" > -->
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text >
                    <b-icon icon="upc-scan" variant="secondary" scale="1.1"/>
                  </b-input-group-prepend>

                  <b-form-input
                    size="md" 
                    name="webhook-activation-url" 
                    :readonly="true" 
                    autocomplete="off" 
                    :disabled="editInputBlocked.identifier" 
                    :value="webhookActivationUrl()" 
                    class="text-secondary" 
                  />
                </b-input-group>
              <!-- </b-overlay> -->
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col>
              <b-button variant="outline-dark" class="px-50 py-50 small square rounded-bottom ml-75" @click="copyToken('url')">
                <small class="text-secondary">Copy Activation URL
                  <b-icon icon="clipboard" scale=".9"/>
                </small>
              </b-button>
            </b-col>
          </b-row>

          <!-- EDIT STATUS ================ -->
          <b-row no-gutters class="mt-2">
            <!-- <b-col md="12" class="d-flex align-items-center">
              <b>Status:</b>
            </b-col> -->

            <b-col md="12" class="d-flex align-items-center mt-25"> 
              <span class="mr-2 mt-25"><b>Status:</b></span>
              <b-overlay :show="loading.status" variant="none" spinner-small>
                <b-input-group class="input-group-merge shadow-none">
                    
                    <div class="mt-25">
                      <big>
                        <b-badge pill variant="light-danger" v-if="!webhookStatus">Inactive</b-badge> 
                        <b-badge pill variant="light-success" v-else>Active</b-badge> 
                      </big>
                    </div>

                    <template v-if="webhookChanging">
                      <b-form-checkbox switch v-model="webhookStatus" class="ml-1 mt-25" />
                    </template>

                    <b-button :disabled="userCantEdit" class="py-0 mt-25" variant="none" v-if="!webhookChanging" @click="webhookChanging = true" >
                      <span>
                        <b-icon icon="pencil-square" class="mr-25"/>
                        Edit Status
                      </span>
                    </b-button>
                    <b-button v-else class="py-0 mt-25" variant="none" @click="updateStatus()">
                      <span  class="text-success">
                        <b-icon icon="check2" class="mr-25"/>
                        Save Status
                      </span>
                    </b-button>
                </b-input-group>
              </b-overlay>
            </b-col>
          </b-row>
          <!-- ========================== -->


          <div align="right" class="mt-2">
            <b-button :disabled="userCantEdit" variant="outline-danger" class="py-50 px-1" @click="$refs['confirm-delete-modal'].show()">
              <b-icon icon="trash" class="mr-50"/>
              Delete Webhook
            </b-button>
          </div>
          </div>

       </b-col>
      </b-row>
    </b-card>
    </template>

    <b-card class="mt-3" v-else>
      <div class="d-flex align-content-center justify-content-between mb-2">
        <b-skeleton height="40px" width="400px"/>
        <div align="right">
          <b-skeleton height="15px" width="200px"/>
          <b-skeleton height="15px" width="150px"/>
        </div>
      </div>
      <div class="d-flex">
        <b-skeleton height="20px" width="150px" class="mr-2"/>
        <b-skeleton height="20px" width="150px"/>
      </div>

      <div class="mt-3">
        <b-skeleton height="20px" width="100px"/>
        <b-skeleton height="30px" width="100%"/>
      </div>

      <div class="mt-3">
        <b-skeleton height="20px" width="100px"/>
        <b-skeleton height="30px" width="100%" class="mb-0"/>
        <div class="d-flex">
          <b-skeleton height="20px" width="150px" class="ml-1" animation="fade"/>
          <b-skeleton height="20px" width="150px" class="ml-1" animation="fade"/>
          <b-skeleton height="20px" width="150px" class="ml-1" animation="fade"/>
        </div>
      </div>

      <div class="mt-3 d-flex">
        <b-skeleton height="20px" width="100px" class=""/>
        <b-skeleton height="20px" width="80px" class="ml-3"/>
        <b-skeleton height="20px" width="120px" class="ml-1"/>
      </div>
      <div align="right">
        <b-skeleton height="40px" width="200px"/>
      </div>
      
    </b-card>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import { makeToast } from '@/layouts/components/Popups';

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
  VBTooltip,
  BTable,
  BBadge,
  BPopover,
  BCollapse,
  BModal,
  BFormSelect,
  BSpinner,
  BOverlay,
  BTooltip,
  BCard

} from "bootstrap-vue";


export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BSkeleton,
    BTabs,
    BTab,
    BCardText,
    VBTooltip,
    BTable,
    BBadge,
    BPopover,
    BCollapse,
    BModal,
    BFormSelect,
    BSpinner,    
    BOverlay,
    BTooltip,
    BCard
  },

  data() {
    return {
      webhookStatus: false,
      webhookChanging: false,
      editInputBlocked:{
        identifier: true,
        token: true
      },
      loading:{
        identifier:false,
        token: false,
        status: false,
        deleteWebhook: false
      },
      mockIdentifier: undefined,
      tokenVisible: false,
      shakingEditName: false,

      urlOptions: {
        query: {text: 'Run Synchronously', value: 'sync'},
        async: {text: 'Run Asynchronously', value: 'async'},
        merge: {text: 'Merge Responses', value: 'merge'}
      },
      urlAsQuery: false,
      selectedUrlOption: 'sync'
    }
  },
  computed: {
    ...mapGetters('transmissionWebhook', ['getWebhookInfo']),
    webhook(){
      return this.getWebhookInfo
    },
    userCantEdit(){
      return !this.$can('create')
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.clearWebhhookInfo()
      this.fetchWebhookInfo()
    },
    clearWebhhookInfo(){
      this.$store.dispatch("transmissionWebhook/clearWebhhookInfo")
    },
    fetchWebhookInfo(){
      this.$store
      .dispatch("transmissionWebhook/getWebhookInfo", {transmissionID: this.$route.params.transmissionID, webhookID: this.$route.params.webhookID})
      .then((resp) => {
        this.mockIdentifier = this.webhook.identifier
        this.putWebhookStatusLocally()
      })
      .catch((error) => {
        console.error(error);
      });
    },
    formatLLL(date){
      return moment(date).format('LLL')
    },
    shakeEditName(){
      if (this.webhookChanging) return;

      this.shakingEditName = true;
      setTimeout(() => {
        this.shakingEditName = false;
      }, 1000);
    },
    copyToken(type){
      let r
      const transmissionIdentifier = this.$store.getters.getSelectedTransmissionGroup.identifier

      if (type == 'raw'){
        r = this.webhook.token        
      } else {
        r = this.webhookActivationUrl()
      }
      makeToast({
        title: 'Success!',
        text: 'Token copied to Clipboard',
        variant: "success",
        icon: "CopyIcon",
      });
      navigator.clipboard.writeText(String(r))
    },
    webhookActivationUrl(){
      const transmissionIdentifier = this.$store.getters.getSelectedTransmissionGroup.identifier
      
      let location = 'https://instance.fique.online' //it's Window.location.origin but replacing 'app' with 'instance'
      let r;

      let option = structuredClone(this.selectedUrlOption)

      switch (option) {
        case 'sync':{
          r = `${location}/webhook/${transmissionIdentifier}/${this.webhook.identifier}`
          break;
        }
        case 'async':{
          r = `${location}/webhook/async/${transmissionIdentifier}/${this.webhook.identifier}`
          break;
        }        
        case 'merge':{
          r = `${location}/webhook/merge/${transmissionIdentifier}/${this.webhook.identifier}`
          break;
        }
      }

      if(this.urlAsQuery){
        r = r + "?token=" + this.webhook.token
      } else {
        r = r + "/" + this.webhook.token
      }

      return r
    },
    putWebhookStatusLocally(){
      if (!this.webhook) return;
      let r = undefined
      if (this.webhook.enum_status.id == 2){
        r = true
      }
      if (this.webhook.enum_status.id == 1){
        r = false
      }

      this.webhookStatus = r;
    },
    allowEditField(field){
      this.editInputBlocked[field] = false
      this.$nextTick(()=>{
        this.$refs['edit-'+field].focus()
      })
    },
    updateName(){
      this.loading.identifier = true;

      const data = {
        new_value: this.mockIdentifier
      }
      this.$store
      .dispatch("transmissionWebhook/patchWebhookIdentifier", {transmissionID: this.$route.params.transmissionID, webhookID: this.$route.params.webhookID, data: data})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook name updated successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.mockIdentifier = this.webhook.identifier
      })
      .catch((error) => {
        console.error(error);
        if (this.mockIdentifier == this.webhook.identifier){
          makeToast({
            title: 'Error!',
            text: "Name of Webhook can't stay the same",
            variant: "danger",
            icon: "XIcon",
          });
        } else {
          makeToast({
            title: 'Error!',
            text: "Name couldn't be updated",
            variant: "danger",
            icon: "XIcon",
          });
        }
      })
      .finally(()=>{
        this.loading.identifier = false;
        this.editInputBlocked.identifier = true;
      })
    },
    refreshToken(){
      this.loading.token = true;
      
      this.$store
      .dispatch("transmissionWebhook/changeWebhookToken", {transmissionID: this.$route.params.transmissionID, webhookID: this.$route.params.webhookID})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook Token refreshed successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.loading.token = false;
        this.$nextTick(()=>{
          this.$refs['confirm-refresh-modal'].hide()
        })
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        this.loading.token = false;
      })
    },
    updateStatus(){
      this.loading.status = true;
      let value;
      if(this.webhookStatus){
        value = 2
      } else {
        value = 1
      }
      

      const data = {
        new_value: value
      }

      this.$store
      .dispatch("transmissionWebhook/changeWebhookStatus", {transmissionID: this.$route.params.transmissionID, webhookID: this.$route.params.webhookID, data: data})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook Status updated successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.putWebhookStatusLocally();
        this.webhookChanging = false;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        this.loading.status = false;
      })
    },
    deleteWebhook(){
      this.loading.deleteWebhook = true

      this.$store
      .dispatch("transmissionWebhook/deleteWebhook", {transmissionID: this.$route.params.transmissionID, webhookID: this.$route.params.webhookID})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook deleted successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.$router.push(`/transmission/${this.$route.params.transmissionID}/webhooks`)
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        this.loading.deleteWebhook = false
      })
    }

  },
}
</script>

<style lang="scss" scoped>
.whitespace-nowrap{
  white-space: nowrap;
}
</style>